

export const scriptUrl = process.env.NEXT_PUBLIC_SCRIPT_URI || "https://luxola.ru";

export const campaignId = process.env.NEXT_PUBLIC_CAMPAIGN_ID || "1tzmXfHCxOwMpu6RvaAnX1Etgyl"//"1nHpzeDBoBM1n1ksHoP4F3e6tcM";



export const serviceWorkerTimeout =
  parseInt(process.env.SERVICE_WORKER_TIMEOUT || "", 10) || 60 * 1000;


export const exportMode = process.env.NEXT_EXPORT === "true";

export const ssrMode = typeof window === "undefined";

export const incrementalStaticRegenerationRevalidate = false

export const staticPathsFetchBatch = 100;

export const staticPathsFallback = (exportMode
  ? false
  : process.env.NEXT_PUBLIC_STATIC_PATHS_FALLBACK) | "blocking";




export const faviconDefault = "/favicon.ico"


export const META_DEFAULTS = {
  custom: [],
  description:
    "Default description",
  image: "",
  title: "Northwest Seafood Express",
  type: "website",
};
